import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Login from './components/Login';
import SalesforceLogin from './components/SalesforceLogin';

function App() {
  return (
    <Router basename="/console">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/salesforce-login" element={<SalesforceLogin />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Call useNavigate to get the navigate function

  const apiBaseUrl = process.env.REACT_APP_NODE_ENV === 'development' 
    ? process.env.REACT_APP_API_URL_DEV // Development API URL
    : process.env.REACT_APP_API_URL; // Production API URL


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("apiBaseUrl",apiBaseUrl);
      // const response = await axios.post('https://unified-backend-38e9761596ff.herokuapp.com/users/login', {
      const response = await axios.post(`${apiBaseUrl}/users/login`, {
          username,
        password
      }, {
        withCredentials: true // Ensure cookies are sent with the request
      });
      localStorage.setItem('token', response.data.token); // Storing the token if you need it for other purposes
      alert('Login successful');
      navigate('/salesforce-login');
    } catch (error) {
      alert('Login failed');
    }
  };
  

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2 className="login-title">Welcome Back!</h2>
        <div className="input-group">
          <input type="text" className="login-input" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div className="input-group">
          <input type="password" className="login-input" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button className="login-button" type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;

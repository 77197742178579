import React from 'react';

function SalesforceLogin() {
  // const handleSalesforceLogin = () => {
  //   const token = localStorage.getItem('token'); // Get the token from local storage
  //   alert(token)
  //   window.location.href = `http://localhost:3000/salesforce/auth?token=${encodeURIComponent(token)}`;
  // };

  const apiBaseUrl = process.env.REACT_APP_NODE_ENV === 'development' 
  ? process.env.REACT_APP_API_URL_DEV // Development API URL
  : process.env.REACT_APP_API_URL; // Production API URL

  function handleSalesforceLogin() {
    const token = localStorage.getItem('token'); // Get the token from local storage
  
    // fetch('https://unified-backend-38e9761596ff.herokuapp.com/salesforce/auth', {
      fetch(`${apiBaseUrl}/salesforce/auth`, {

      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      credentials: 'include',
    })
    .then(response => {
      if (response.ok && response.headers.get('content-type')?.includes('application/json')) {
        return response.json();
      }
      throw new Error('Non-JSON response received');
    })
    .then(data => {
      if (data.url) {
        window.location.href = data.url;
      } else {
        console.error('No URL provided for redirection');
      }
    })
    .catch(error => console.error('Error:', error));
  }
  


  function handleStripeLogin() {
    const token = localStorage.getItem('token');
  
    // fetch('https://unified-backend-38e9761596ff.herokuapp.com/stripe/connect', {
      fetch(`${apiBaseUrl}/stripe/connect`, {

      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      credentials: 'include',
    })
    .then(response => {
      if (response.ok && response.headers.get('content-type')?.includes('application/json')) {
        return response.json();
      }
      throw new Error('Non-JSON response received');
    })
    .then(data => {
      if (data.url) {
        console.log(data.url);
        window.location.href = data.url; // Perform the redirection
      } else {
        console.error('No URL provided for redirection');
      }
    })
    .catch(error => console.error('Error:', error));
  }
  


  return (
    <div>
      <h1>Connected Apps Login</h1>
      <button onClick={handleSalesforceLogin}>Login to Salesforce</button>
      <button onClick={handleStripeLogin}>Connect with Stripe</button>
    </div>
  );
}

export default SalesforceLogin;